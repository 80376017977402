import dynamic from 'next/dynamic'
import { Fade } from 'react-awesome-reveal'
import { css } from '@emotion/react'

const PlPartners = dynamic(() => import('@/components/sections/PartnersPl'))
const AePartners = dynamic(() => import('@/components/sections/PartnersAe'))

import { Grid } from '@/styles/grid'
import { mediaColumns } from '@/styles/grid'
import { mediaDown, mediaUp } from '@/styles/media'

import { useMarket } from '@/utils/multi-markets/context'

const components = {
  bh: AePartners,
  ae: AePartners,
  pl: PlPartners
}

function Parking() {
  const { market } = useMarket()
  const PartnersComponent = components[market]

  return (
    <div css={container}>
      <Fade triggerOnce>
        <Grid>
          <ul css={partners}>
            <PartnersComponent />
          </ul>
        </Grid>
      </Fade>
    </div>
  )
}

const container = css`
  overflow: hidden;
  margin-bottom: 40px;
  margin-top: -8px;

  ${mediaUp('lg')} {
    margin-bottom: 60px;
  }
`

const partners = css`
  ${mediaColumns({ xs: [12, 0], md: [10, 1], lg: [12, 0] })};
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -14px;
  margin-left: -14px;

  ${mediaUp('lg')} {
    margin-right: -35px;
    margin-left: -35px;
  }

  ${mediaDown('md')} {
    flex-wrap: wrap;
  }
`

export default Parking
