import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'
import { spacingSection } from '@/styles/mixins'

export const Container = styled.section`
  ${spacingSection};
`

export const TitleWrapper = styled.div`
  margin-bottom: 40px;

  ${mediaUp('lg')} {
    margin-bottom: 80px;
  }
`
