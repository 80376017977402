import React from 'react'

import { Svg } from '@/components/icons/Twitter.styled'

function Twitter() {
  return (
    <Svg
      width="18"
      height="15"
      viewBox={'0 0 18 15'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 1.731a7.694 7.694 0 0 1-2.126.583 3.67 3.67 0 0 0 1.623-2.04c-.713.426-1.5.726-2.34.894A3.689 3.689 0 0 0 8.774 3.69c0 .293.025.574.085.842A10.445 10.445 0 0 1 1.253.673a3.715 3.715 0 0 0-.505 1.865c0 1.278.658 2.41 1.64 3.067A3.644 3.644 0 0 1 .72 5.15v.04a3.707 3.707 0 0 0 2.957 3.627c-.3.082-.628.121-.968.121-.236 0-.475-.013-.699-.063a3.725 3.725 0 0 0 3.449 2.57A7.415 7.415 0 0 1 .883 13.02c-.302 0-.593-.014-.883-.051a10.389 10.389 0 0 0 5.661 1.656c6.79 0 10.503-5.625 10.503-10.5 0-.164-.006-.321-.014-.478A7.362 7.362 0 0 0 18 1.731z"
        fill="#3B8DED"
      />
    </Svg>
  )
}

export default Twitter
