import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'

import { useMarket } from '@/utils/multi-markets/context'

import LogoDetails from '@/components/common/LogoDetails'
import LinkedIn from '@/components/icons/LinkedIn'
import Facebook from '@/components/icons/Facebook'
import Twitter from '@/components/icons/Twitter'
import Instagram from '@/components/icons/Instagram'
import Button from '@/components/common/Button'

import {
  AddressWrapper,
  Description,
  IconWrapper,
  LogoWrapper,
  Social,
  Socials,
  Wrapper,
  ButtonWrapper,
  ContactText,
  Divider,
  CompanyName,
  Address
} from './ContactInfo.styled'

const AeContactAddress = (
  <>
    <CompanyName>{'NaviParking DMCC'}</CompanyName>
    <Address>
      {parse(
        'Unit No: RET-R6-053<br>Detached Retail R6<br>Plot No: JLT-PH2-RET-R6<br>Jumeirah Lakes Towers<br>Dubai<br>United Arab Emirates<br>'
      )}
      {parse('customer.care@naviparking.ae <br>')}
      {'(+971) 50 314 0719 WhatsApp only'}
    </Address>
  </>
)

const PlContactAddress = (
  <>
    <CompanyName>{'NaviParking Poland Sp. z o.o.'}</CompanyName>
    <Address>
      {parse('ul. Chmielna 73 <br> 00-801 Warszawa <br> Polska')}
      {parse('<br> +48 516 900 102')}
    </Address>
  </>
)

const components = {
  bh: AeContactAddress,
  ae: AeContactAddress,
  pl: PlContactAddress
}

function ContactInfo() {
  const { t } = useTranslation('common')
  const { market } = useMarket()
  const AddressComponent = components[market]

  return (
    <Wrapper>
      <Description>{parse(t('contact.description'))}</Description>

      <ContactText>{t('contact.moreInfo')}</ContactText>
      <ButtonWrapper>
        <Button
          text={t('contact.moreInfoButton')}
          link={{ pathname: '/faq' }}
        />
      </ButtonWrapper>
      <Divider />
      <LogoWrapper>
        <LogoDetails />
      </LogoWrapper>

      <AddressWrapper>{AddressComponent}</AddressWrapper>

      <Socials>
        <Social>
          <IconWrapper
            href={t('socials.linkedin')}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t('a11y.linkedinButton')}
          >
            <LinkedIn />
          </IconWrapper>
        </Social>

        <Social>
          <IconWrapper
            href={t('socials.facebook')}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t('a11y.facebookButton')}
          >
            <Facebook />
          </IconWrapper>
        </Social>

        <Social>
          <IconWrapper
            href={t('socials.twitter')}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t('a11y.twitterButton')}
          >
            <Twitter />
          </IconWrapper>
        </Social>

        <Social>
          <IconWrapper
            href={t('socials.instagram')}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={t('a11y.instagramButton')}
          >
            <Instagram />
          </IconWrapper>
        </Social>
      </Socials>
    </Wrapper>
  )
}

export default ContactInfo
