import React from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'next-i18next'

import Textarea from '@/components/form/Textarea'

import { ContactForm } from './type'

interface Props {
  control: Control<ContactForm>
}

function MessageController(props: Props) {
  const { control } = props
  const { t } = useTranslation('common')
  const { field, fieldState } = useController<ContactForm, 'content'>({
    control,
    name: 'content',
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: t('form.errors.required')
      }
    }
  })

  return (
    <Textarea
      label={t('contact.form.message')}
      placeholder={t('contact.form.message')}
      value={field.value}
      error={fieldState.error?.message}
      hideLabel
      onChange={field.onChange}
      onBlur={field.onBlur}
      dataTestId={'contact-form-message'}
    />
  )
}

export default MessageController
