import Select, { Props as ReactSelectProps } from 'react-select'

import { OptionTypeBase } from 'react-select/src/types'

import Error from '@/components/form/Error'

import { CustomSelectWrapper } from './Dropdown.styled'
import { Field, FieldLabel, FieldLabelWrapper } from './Field'

interface Props<T extends OptionTypeBase> extends ReactSelectProps<T, false> {
  label: string
  error?: string
  hideLabel?: boolean
  noMargin?: boolean
  noIcon?: boolean
  dataTestId?: string
}

function Dropdown<T extends OptionTypeBase>(props: Props<T>) {
  const {
    label,
    error,
    hideLabel = false,
    noMargin = false,
    isDisabled = false,
    noIcon = false,
    dataTestId,
    ...rest
  } = props

  return (
    <Field noMargin={noMargin}>
      <FieldLabelWrapper>
        <FieldLabel visuallyHidden={hideLabel}>{label}</FieldLabel>
        <CustomSelectWrapper
          invalid={!!error}
          isDisabled={isDisabled}
          noIcon={noIcon}
          data-test-id={dataTestId}
        >
          <Select
            className="custom-select-container"
            classNamePrefix="custom-select"
            isSearchable={false}
            isDisabled={isDisabled}
            blurInputOnSelect={false}
            {...rest}
          />
        </CustomSelectWrapper>
      </FieldLabelWrapper>
      {error && <Error error={error} />}
    </Field>
  )
}

export default Dropdown
