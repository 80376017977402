import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { Control, useController } from 'react-hook-form'

import Dropdown from '@/components/form/Dropdown'
import { first } from '@/utils/common'
import { useFetchPaginationParkings } from '@/service/apiHooks/useFetchPaginationParkings'
import { useMarket } from '@/utils/multi-markets/context'

import { ContactForm } from './type'

interface ShortParking {
  shortDisplayName: string
}

interface Props {
  control: Control<ContactForm>
  defaultValue?: string
}

function ParkingController(props: Props) {
  const { control, defaultValue } = props
  const { t } = useTranslation('common')
  const { locale, market } = useMarket()

  const { parkings, fetchNextPage, hasNextPage } = useFetchPaginationParkings({
    params: {
      lang: locale,
      size: 10,
      countryCode: market,
      sorting: 'distance;asc'
    }
  })

  const { field } = useController<ContactForm, 'label'>({
    control,
    name: 'label',
    defaultValue: defaultValue || t('form.otherIssue')
  })

  const [options, setOptions] = useState<ShortParking[]>([])

  const value =
    options?.find(option => field.value === option.shortDisplayName) ?? null

  const handleChange = useCallback(
    (selectedOption: ShortParking | null) => {
      if (selectedOption) {
        field.onChange(selectedOption.shortDisplayName)
      } else {
        field.onChange('')
      }
    },
    [field]
  )

  useEffect(() => {
    if (!parkings) return

    const parkingsOptions =
      parkings.pages
        ?.flatMap((page: any) => page?.items)
        ?.map(parking => ({
          shortDisplayName: parking?.shortDisplayName ?? ''
        })) || []

    const newOptions = [
      {
        shortDisplayName: t('form.otherIssue')
      },
      ...parkingsOptions
    ]

    setOptions(newOptions)

    const defaultOption = newOptions.find(
      option => option.shortDisplayName === field.value
    )
    handleChange(defaultOption || first(newOptions))
  }, [parkings])

  const handleScroll = () => {
    if (hasNextPage) {
      fetchNextPage()
    }
  }

  function noOptionsMessage() {
    return options.length ? t('form.noParkings') : t('form.parkingsLoading')
  }

  return (
    <Dropdown<ShortParking>
      //@ts-ignore
      inputRef={field.ref}
      instanceId="parking-uuid"
      getOptionLabel={option => option.shortDisplayName}
      getOptionValue={option => option.shortDisplayName}
      label={t('contact.form.parking')}
      placeholder={t('contact.form.parking')}
      options={options}
      value={value}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      onMenuClose={field.onBlur}
      onMenuScrollToBottom={handleScroll}
      hideLabel
      dataTestId={'contact-form-parking'}
    />
  )
}

export default ParkingController
