/**
 * Validate phone input market specific
 */
import { useTranslation } from 'next-i18next'

interface Phone {
  number: string
  format?: string
  countryCode?: string
}

function usePhoneValidation() {
  const { t } = useTranslation('common')

  // function phoneValidation(value: Phone) {
  //   const format = value.format || ''
  //   const countryCode = value.countryCode || ''
  //   const length = format.match(/\./g)?.length

  //   if (countryCode === 'ae' || countryCode === 'bh') {
  //     return value.number.length === 12 || (t('form.errors.phone') as string)
  //   } else if (length) {
  //     return (
  //       value.number.length === length || (t('form.errors.phone') as string)
  //     )
  //   } else {
  //     return t('form.errors.required') as string
  //   }
  // }

  function phoneRequired(value: Phone) {
    if (!value.countryCode || !value.format || value.number.length < 9) {
      return t('form.errors.required') as string
    }
  }

  return {
    // phoneValidation,
    phoneRequired
  }
}

export default usePhoneValidation
