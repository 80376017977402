import React from 'react'

import { Svg } from '@/components/icons/Instagram.styled'

function Instagram() {
  return (
    <Svg
      width="20"
      height="21"
      viewBox={'0 0 20 21'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#3B8DED">
        <path d="M19.945 6.38c-.046-1.063-.218-1.793-.465-2.426a4.88 4.88 0 0 0-1.156-1.774 4.922 4.922 0 0 0-1.77-1.153C15.917.781 15.191.61 14.128.563 13.058.512 12.718.5 10.002.5 7.287.5 6.947.512 5.88.559c-1.063.046-1.793.218-2.426.464A4.88 4.88 0 0 0 1.68 2.18 4.924 4.924 0 0 0 .527 3.95C.281 4.587.11 5.313.063 6.376.012 7.446 0 7.786 0 10.502c0 2.715.012 3.055.059 4.122.046 1.063.218 1.793.465 2.426a4.932 4.932 0 0 0 1.156 1.774c.5.508 1.106.903 1.77 1.153.637.246 1.363.418 2.426.464 1.067.047 1.407.059 4.122.059 2.716 0 3.055-.012 4.122-.059 1.063-.046 1.793-.218 2.426-.464a5.116 5.116 0 0 0 2.927-2.927c.246-.637.418-1.363.465-2.426.046-1.067.058-1.407.058-4.122s-.004-3.055-.05-4.122zm-1.8 8.166c-.044.977-.208 1.504-.345 1.856a3.318 3.318 0 0 1-1.898 1.898c-.352.137-.883.301-1.856.344-1.055.047-1.372.059-4.04.059-2.669 0-2.989-.012-4.04-.059-.977-.043-1.504-.207-1.856-.344a3.078 3.078 0 0 1-1.148-.746 3.11 3.11 0 0 1-.747-1.148c-.136-.352-.3-.883-.343-1.856-.047-1.055-.059-1.372-.059-4.04 0-2.669.012-2.99.059-4.04.042-.977.207-1.504.343-1.856a3.04 3.04 0 0 1 .75-1.149 3.105 3.105 0 0 1 1.15-.746c.35-.137.882-.3 1.855-.344 1.055-.046 1.371-.058 4.04-.058 2.672 0 2.989.012 4.04.058.976.043 1.504.207 1.856.344.433.16.828.414 1.148.746.332.325.586.715.746 1.15.137.35.301.882.344 1.855.047 1.055.059 1.371.059 4.04 0 2.668-.012 2.98-.059 4.036z" />
        <path d="M10.002 5.364a5.139 5.139 0 0 0-5.138 5.138 5.139 5.139 0 1 0 5.138-5.138zm0 8.47a3.333 3.333 0 1 1 .001-6.666 3.333 3.333 0 0 1-.001 6.667zM16.542 5.161a1.2 1.2 0 1 1-2.399 0 1.2 1.2 0 0 1 2.4 0z" />
      </g>
    </Svg>
  )
}

export default Instagram
