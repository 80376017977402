import React from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'next-i18next'

import PhoneInput from '@/components/form/PhoneInput'
import usePhoneValidation from '@/utils/usePhoneValidation'

import { ContactForm } from './type'

interface Props {
  control: Control<ContactForm>
}

function PhoneController(props: Props) {
  const { control } = props
  const { t } = useTranslation('common')
  const { phoneRequired } = usePhoneValidation()
  const { field, fieldState } = useController<ContactForm, 'phone'>({
    control,
    name: 'phone',
    defaultValue: { number: '', format: '', countryCode: '' },
    rules: {
      validate: phoneRequired
    }
  })

  return (
    <PhoneInput
      label={t('contact.form.phone')}
      placeholder={t('contact.form.phone')}
      value={field.value.number}
      error={fieldState.error?.message}
      onChange={field.onChange}
      onBlur={field.onBlur}
      hideLabel
      dataTestId={'contact-form-phone'}
    />
  )
}

export default PhoneController
