import React from 'react'

import { Svg } from '@/components/icons/Facebook.styled'

function Facebook() {
  return (
    <Svg viewBox={'0 0 9 18'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.357 2.989h1.644V.127A21.22 21.22 0 0 0 6.607 0c-2.37 0-3.993 1.49-3.993 4.23v2.52H0v3.2h2.615V18H5.82V9.95h2.509l.398-3.2H5.819V4.547c.001-.924.25-1.557 1.538-1.557z"
        fill="#3B8DED"
      />
    </Svg>
  )
}

export default Facebook
