import React from 'react'

function Cloud() {
  return (
    <svg viewBox={'0 0 28 28'} xmlns="http://www.w3.org/2000/svg">
      <path
        d="m14 13.874 5.546 5.546a1.402 1.402 0 0 1-.993 2.396c-.359 0-.718-.136-.992-.411l-2.157-2.157v6.305a1.404 1.404 0 0 1-2.808 0v-6.305l-2.157 2.157a1.402 1.402 0 0 1-1.985 0 1.402 1.402 0 0 1 0-1.985L14 13.874zM13.36 1.75a9.118 9.118 0 0 1 8.33 5.384c3.557.525 6.31 3.616 6.31 7.3a7.373 7.373 0 0 1-5.042 7.004 1.404 1.404 0 0 1-.888-2.663 4.57 4.57 0 0 0 3.123-4.34c0-2.486-2.024-4.537-4.512-4.57l-1-.015-.313-.948a6.317 6.317 0 0 0-6.008-4.345c-3.49 0-6.33 2.84-6.33 6.33 0 .267.023.53.056.79l.193 1.575H5.686a2.882 2.882 0 0 0-2.879 2.878 2.882 2.882 0 0 0 2.705 2.875 1.403 1.403 0 0 1-.083 2.804c-.028 0-.056 0-.085-.002A5.69 5.69 0 0 1 0 16.13c0-2.63 1.795-4.85 4.226-5.496C4.36 5.71 8.406 1.75 13.36 1.75z"
        fill="#3B8DED"
      />
    </svg>
  )
}

export default Cloud
