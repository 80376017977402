import styled from '@emotion/styled'

import { mediaColumns } from '@/styles/grid'
import { mediaDown, mediaUp } from '@/styles/media'
import { LegiaBlack, LegiaMedium, Text1Styles } from '@/styles/typography'
import { colors } from '@/styles/theme'
import { focusVisible } from '@/styles/mixins'

export const Wrapper = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [6, 0] })};
  padding-inline-start: 29px;

  ${mediaDown('md')} {
    padding-inline-start: 0;
    text-align: center;
  }

  ${mediaUp('lg')} {
    padding-inline-start: 55px;
  }
`

export const Description = styled.p`
  ${Text1Styles};
  margin-bottom: 18px;

  ${mediaDown('md')} {
    text-align: center;
  }

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }

  .mobile {
    ${mediaUp('md')} {
      display: none;
    }
  }

  .desktop {
    display: none;
    ${mediaUp('md')} {
      display: block;
    }
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);

    strong {
      ${LegiaBlack};
    }
  }
`

export const AddressWrapper = styled.address`
  display: flex;
  flex-direction: column;
  font-style: normal;

  ${mediaDown('md')} {
    justify-content: center;
  }
`

export const LogoWrapper = styled.div`
  max-width: 132px;
  height: 30px;
  margin-bottom: 18px;

  svg {
    width: 100%;
  }

  ${mediaDown('md')} {
    margin: 0 auto 18px;
  }

  ${mediaUp('lg')} {
    max-width: 290px;
    height: 61px;
    margin-bottom: 40px;
  }
`

export const CompanyName = styled.span`
  ${Text1Styles};
  margin-bottom: 4px;

  ${mediaDown('md')} {
    text-align: center;
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`

export const Address = styled.span`
  ${Text1Styles};
  margin-bottom: 18px;

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`

export const Socials = styled.ul`
  list-style: none;
  display: flex;

  ${mediaDown('md')} {
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }
`

export const Social = styled.li`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 27.5px;
  margin-inline-end: 20px;
  background-color: ${colors.paleGrey};

  ${mediaDown('md')} {
    margin: 0 5px;
  }

  ${mediaUp('lg')} {
    width: 48px;
    height: 48px;
  }

  .legia-warszawa & {
    border-radius: 0;
    background-color: var(--tintCustom);
  }
`

export const IconWrapper = styled.a`
  ${focusVisible};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .legia-warszawa & {
    path {
      fill: var(--accentFirstCustom);
    }
  }
`

export const ButtonWrapper = styled.div`
  margin: 0 auto;
  max-width: 230px;

  ${mediaUp('md')} {
    margin: 0;
  }

  ${mediaUp('lg')} {
    max-width: 430px;
  }
`

export const ContactText = styled.p`
  ${Text1Styles};
  margin: 18px 0;

  ${mediaUp('lg')} {
    margin: 40px 0 20px;
  }

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`

export const Divider = styled.div`
  height: 2px;
  width: 100%;
  max-width: 220px;
  background-color: ${colors.twilightBlue};
  opacity: 0.08;
  margin: 20px auto;

  ${mediaUp('md')} {
    width: calc(100% - 29px);
    margin: 20px 0;
    max-width: none;
  }

  ${mediaUp('lg')} {
    width: calc(100% - 55px);
    margin: 40px 0;
  }
`
