import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { spacingButton } from '@/styles/mixins'
import { border, colors, transitions } from '@/styles/theme'
import { InputTextStyles, LegiaMedium } from '@/styles/typography'
import { mediaUp } from '@/styles/media'

export const TextareaComponent = styled.textarea<{ $invalid?: boolean }>`
  ${InputTextStyles};
  ${spacingButton};
  border-radius: ${border.radiusMobile};
  box-shadow: ${border.shadow};
  background-color: ${colors.white};
  border: 1px solid transparent;
  outline: 0;
  color: var(--main);
  transition: border-color ${transitions.base};
  resize: vertical;
  width: 100%;

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }

  &:focus {
    border-color: var(--accentFirst);

    .legia-warszawa & {
      border-color: var(--accentFirstCustom);
    }
  }

  ${props =>
    props.$invalid &&
    css`
      border-color: ${colors.darkCoral};
    `}

  ${mediaUp('lg')} {
    border-width: 2px;
    border-radius: ${border.radius};
  }

  .legia-warszawa & {
    border-radius: 0;
  }
`
