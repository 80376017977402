import styled from '@emotion/styled'

const Spinner = ({ fullPage = false }: { fullPage?: boolean }) => (
  <SpinnerCnt fullPage={fullPage}>
    <StyledSpinner />
  </SpinnerCnt>
)

export default Spinner

const StyledSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  .pos-center {
    position: fixed;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
  @media (min-width: 634px) {
    border: 10px solid #f3f3f3;
    border-top: 10px solid #3498db;
    width: 80px;
    height: 80px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const SpinnerCnt = styled.div<{ fullPage?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ fullPage }) => (fullPage ? '100vw' : '100%')};
  height: ${({ fullPage }) => (fullPage ? '100vh' : '100%')};
`
