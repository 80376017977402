import React from 'react'

import { Svg } from './LinkedIn.styled'

function LinkedIn() {
  return (
    <Svg viewBox={'0 0 18 18'} xmlns="http://www.w3.org/2000/svg">
      <g fill="#3B8DED">
        <path d="M2.183 0C.863 0 0 .867 0 2.006 0 3.12.837 4.01 2.132 4.01h.025c1.346 0 2.183-.891 2.183-2.005C4.315.866 3.503 0 2.183 0zM.228 5.596h3.859v11.61H.228zM13.558 5.324c-2.082 0-3.478 1.956-3.478 1.956V5.596H6.222v11.61h3.858v-6.484c0-.347.025-.693.127-.941.28-.693.914-1.411 1.98-1.411 1.396 0 1.955 1.064 1.955 2.625v6.21H18V10.55c0-3.566-1.904-5.225-4.442-5.225z" />
      </g>
    </Svg>
  )
}

export default LinkedIn
