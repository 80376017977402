import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { useTranslation } from 'next-i18next'

import ArrowIcon from '@/assets/arrow.svg?inline'

import { Text2Styles } from '@/styles/typography'
import { colors, zIndex, weights, transitions } from '@/styles/theme'
import { mediaUp } from '@/styles/media'

function ButtonToTop() {
  const { t } = useTranslation('common')
  const [scrolled, setScrolled] = useState(false)

  function handleScroll() {
    setScrolled(window.pageYOffset > 100)
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <button
      css={[styles.button, scrolled && styles.scrolled]}
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <ArrowIcon />
      <span css={styles.text}>{t('navigation.toTop')}</span>
    </button>
  )
}

const styles = {
  button: css`
    ${Text2Styles};
    transition: opacity ${transitions.base};
    opacity: 0;
    color: ${colors.blueHover};
    font-weight: ${weights.black};
    z-index: ${zIndex.cookies};
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    height: 30px;
    border-radius: 28px;
    background: ${colors.paleHover};
    padding: 0 18px;

    ${mediaUp('lg')} {
      width: 55px;
      height: 55px;
      bottom: 30px;
      right: 80px;
      padding: 0;
    }

    html[lang^='ar'] & {
      left: 20px;
      right: unset;

      ${mediaUp('lg')} {
        left: 80px;
        right: unset;
      }
    }

    svg {
      height: 10px;
      width: 10px;
      margin-inline-end: 4px;
      transform: rotate(-90deg);

      ${mediaUp('lg')} {
        width: 20px;
        height: 20px;
        margin-inline-end: 0;
      }

      path {
        fill: ${colors.blueHover};
      }
    }
  `,
  scrolled: css`
    opacity: 1;
  `,
  text: css`
    ${mediaUp('lg')} {
      display: none;
    }
  `
}

export default ButtonToTop
