import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'

import { AxiosError } from 'axios'

import api from '@/service'

import InputFile from '@/components/form/InputFile'
import Button from '@/components/common/Button'
import Title from '@/components/common/Title'

import NameController from '@/components/sections/ContactSection/NameController'
import EmailController from '@/components/sections/ContactSection/EmailController'
import PhoneController from '@/components/sections/ContactSection/PhoneController'
import MessageController from '@/components/sections/ContactSection/MessageController'
import ParkingController from '@/components/sections/ContactSection/ParkingController'
import Error from '@/components/form/Error'

import { first } from '@/utils/common'

import { ContactForm } from './type'

import { ButtonWrapper, FormWrapper, TitleWrapper } from './Form.styled'

interface Props {
  parkingName?: string
}

function Form(props: Props) {
  const { parkingName } = props

  const form = useForm<ContactForm>({
    mode: 'onTouched'
  })
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [attachmentName, setAttachmentName] = useState('')

  const onSubmit: SubmitHandler<ContactForm> = ({ phone, ...data }) => {
    if (loading) return
    setLoading(true)
    setError('')

    return api.contact
      .post({ ...data, phone: phone.number, attachmentName })
      .then(() => {
        setSuccess(true)
      })
      .catch(err => {
        const response = err.response as AxiosError['response']
        //@ts-ignore
        const error = first(response?.data?.errors) || t('contact.form.error')
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const { t } = useTranslation('common')

  return (
    <>
      {!success ? (
        <FormWrapper onSubmit={form.handleSubmit(onSubmit)}>
          <NameController control={form.control} />
          <EmailController control={form.control} />
          <PhoneController control={form.control} />
          <ParkingController
            control={form.control}
            defaultValue={parkingName}
          />
          <MessageController control={form.control} />

          <InputFile
            onChange={value => setAttachmentName(value)}
            dataTestId={'contact-form-file'}
          />

          {error && <Error error={error} className="error--form" />}

          <ButtonWrapper>
            <Button
              type="submit"
              loading={loading}
              text={t('contact.form.button')}
              dataTestId={'contact-form-button'}
            />
          </ButtonWrapper>
        </FormWrapper>
      ) : (
        <>
          {success && (
            <TitleWrapper data-test-id="contact-form-success">
              <Title text={t('contact.form.success')} />
            </TitleWrapper>
          )}
        </>
      )}
    </>
  )
}

export default Form
