import styled from '@emotion/styled'

import { mediaUp } from '@/styles/media'

export const Svg = styled.svg`
  width: 10px;
  height: 10px;
  ${mediaUp('lg')} {
    width: 20px;
    height: 21px;
  }
`
