import { ReactNode } from 'react'
import { keyframes } from '@emotion/react'
import Reveal from 'react-awesome-reveal'

export const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 24px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

interface Props {
  children: ReactNode
  className?: string
  cascade?: boolean
}

function CustomReveal(props: Props) {
  const { children, cascade, className } = props

  return (
    <Reveal
      className={className}
      keyframes={fadeUp}
      damping={0.3}
      cascade={cascade}
      triggerOnce
    >
      {children}
    </Reveal>
  )
}

export default CustomReveal
