import React from 'react'
import { Control, useController } from 'react-hook-form'
import { useTranslation } from 'next-i18next'

import Input from '@/components/form/Input'

import { EMAIL_REGEX } from '@/constants'

import { ContactForm } from './type'

interface Props {
  control: Control<ContactForm>
}

function EmailController(props: Props) {
  const { control } = props
  const { t } = useTranslation('common')
  const { field, fieldState } = useController<ContactForm, 'email'>({
    control,
    name: 'email',
    defaultValue: '',
    rules: {
      required: {
        value: true,
        message: t('form.errors.required')
      },
      pattern: {
        value: EMAIL_REGEX,
        message: t('form.errors.email')
      }
    }
  })

  return (
    <Input
      ref={field.ref}
      hideLabel
      label={t('contact.form.email')}
      placeholder={t('contact.form.email')}
      value={field.value}
      error={fieldState.error?.message}
      onChange={field.onChange}
      onBlur={field.onBlur}
      type="email"
      dataTestId={'contact-form-email'}
    />
  )
}

export default EmailController
