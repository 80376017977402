import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'

import { AxiosError } from 'axios'

import { convertBytes } from '@/utils/common'

import Error from '@/components/form/Error'
import Loader from '@/components/common/Loader'
import Cloud from '@/components/icons/Cloud'
import api from '@/service'

import { first } from '@/utils/common'

import {
  FileName,
  Files,
  FileWeight,
  IconWrapper,
  Info,
  RemoveFile,
  UploadedFile,
  Wrapper,
  Container
} from './InputFile.styled'

interface Props {
  onChange(value: string): void
  dataTestId?: string
}

function InputFile(props: Props) {
  const { onChange, dataTestId } = props
  const { t } = useTranslation('common')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData()
      if (loading) return
      setError('')
      setLoading(true)
      formData.append('file', acceptedFiles[0])
      api.contact
        .attachment(formData)
        .then(res => {
          onChange(res.data.attachmentName)
        })
        .catch(err => {
          clearInput()
          const response = err.response as AxiosError['response']
          const error =
            //@ts-ignore
            first(response?.data?.errors) || t('contact.form.generalFileError')
          setError(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [onChange]
  )
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 3145728,
    accept: 'image/jpeg, image/png, application/pdf'
  })

  const clearInput = () => {
    acceptedFiles.length = 0
    acceptedFiles.splice(0, acceptedFiles.length)
  }

  const removeAll = () => {
    clearInput()
    onChange('')
    setError('')
  }

  return (
    <Container {...getRootProps()}>
      <input
        {...getInputProps()}
        disabled={!!acceptedFiles.length}
        data-test-id={dataTestId}
      />
      <Wrapper isFilled={!!acceptedFiles.length}>
        {!acceptedFiles.length ? (
          !fileRejections.length ? (
            <>
              {!isDragActive ? (
                <>
                  <IconWrapper>
                    <Cloud />
                  </IconWrapper>
                  <Info>{parse(t('fileInput.baseState'))}</Info>
                </>
              ) : (
                <Info>{t('fileInput.dropState')}</Info>
              )}
            </>
          ) : fileRejections[0].errors[0].code === 'file-too-large' ? (
            <Info>{parse(t('fileInput.maxSize'))}</Info>
          ) : (
            <Info>{parse(t('fileInput.wrongType'))}</Info>
          )
        ) : loading ? (
          <Loader color="twilightBlue" />
        ) : (
          <Files>
            {acceptedFiles.map(file => (
              <UploadedFile key={file.name}>
                <FileName>{file.name}</FileName>
                <FileWeight>{`${convertBytes(file.size)}`}</FileWeight>
              </UploadedFile>
            ))}
            <RemoveFile onClick={removeAll}>{t('fileInput.remove')}</RemoveFile>
          </Files>
        )}
      </Wrapper>
      {error && <Error error={error} className="error--centered" />}
    </Container>
  )
}

export default InputFile
