import { useInfiniteQuery } from '@tanstack/react-query'

import { ApiPaths } from '@/utils/apiPaths'
import { fetchParkings } from '@/utils/parkingsService'

import { AllParkingsParams, AllParkingsResponse } from '../parkings'

interface AllParkingsPaginationParams extends AllParkingsParams {
  size: number
}

export const useFetchPaginationParkings = ({
  params
}: {
  params: AllParkingsPaginationParams
}) => {
  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<AllParkingsResponse, Error>({
      queryKey: [ApiPaths.API_V1_PARKINGS, 'allPages', params.search ?? ''],
      //@ts-ignore
      queryFn: ({ pageParam = 0 }: { pageParam?: number }) => {
        return fetchParkings({
          params: { ...params, page: pageParam }
        })
      },
      getNextPageParam: (lastPage, allPages) => {
        const hasMoreItems = lastPage?.items?.length >= params.size
        return hasMoreItems ? allPages.length + 1 : undefined
      },
      initialPageParam: 0
    })
  return {
    parkings: data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch
  }
}
