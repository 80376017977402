import styled from '@emotion/styled'

import { heightButton } from '@/styles/mixins'
import { border, colors, transitions, weights } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { InputTextStyles, LegiaMedium } from '@/styles/typography'

export const CustomSelectWrapper = styled.div<{
  invalid?: boolean
  isDisabled: boolean
  noIcon: boolean
}>`
  .custom-select__control {
    ${heightButton};
    border-radius: ${border.radius};
    box-shadow: ${border.shadow};
    background-color: ${colors.white};
    border: 1px solid transparent;
    color: var(--main);
    transition: border-color ${transitions.base};
    min-height: auto;
    padding: 0;
    padding-left: 8px;

    &:hover {
      border-color: transparent;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 18px;
      background-size: contain;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMTIuNTg2IDcuNTEgOSAxMS4yMzVhMS4zNzcgMS4zNzcgMCAwIDEtMiAwTDMuNDE0IDcuNTFhMS41MTEgMS41MTEgMCAwIDEgMC0yLjA3OGMuMjY1LS4yNzYuNjI1LS40MzEgMS0uNDMxaDcuMTcyQzEyLjM2NiA1IDEzIDUuNjU4IDEzIDYuNDdhMS41IDEuNSAwIDAgMS0uNDE0IDEuMDR6IiBmaWxsPSIjOTU5ODlDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz4KPC9zdmc+Cg==);
      width: 9px;
      height: 9px;
      transform: translate(0, -50%);
      transition: transform ${transitions.base};

      ${mediaUp('lg')} {
        width: 16px;
        height: 16px;
        right: 40px;
      }

      html[lang^='ar'] & {
        left: 18px;
        right: unset;

        ${mediaUp('lg')} {
          left: 40px;
          right: unset;
        }
      }
    }

    &--menu-is-open::after {
      transform: translate(0, -50%) rotate(180deg);
    }

    &--is-focused {
      &,
      &:hover {
        border-color: var(--accentFirst);
      }
    }

    ${props => props.invalid && `border-color: ${colors.darkCoral};`}
    ${props => props.isDisabled && `background-color: var(--tint);`}

    ${mediaUp('lg')} {
      border-width: 2px;
    }

    .legia-warszawa & {
      border-radius: 0;

      &--is-focused {
        &,
        &:hover {
          border-color: var(--accentFirstCustom);
        }
      }
    }
  }

  .custom-select__value-container {
    padding: 0;
    height: 100%;
  }

  .custom-select__indicators {
    display: none;
  }

  .custom-select__input {
    display: block !important;
  }

  .custom-select__input,
  .custom-select__single-value {
    ${InputTextStyles};
    color: var(--main);

    .legia-warszawa & {
      ${LegiaMedium};
      color: var(--mainCustom);
    }
  }

  .custom-select__placeholder {
    ${InputTextStyles};
    color: ${colors.cloudyBlue};
    margin: 0;
  }

  .custom-select__menu {
    border-radius: ${border.radius};
    box-shadow: ${border.shadow};
    background-color: ${colors.white};
    color: var(--main);
    margin-top: 6px;
    margin-bottom: 6px;
    overflow: hidden;

    ${mediaUp('lg')} {
      margin-top: 12px;
      margin-bottom: 12px;
    }
  }

  .custom-select__menu-list {
    padding: 5px 0;

    ${mediaUp('lg')} {
      padding: 10px 0;
    }
  }

  .custom-select__option {
    display: flex;
    align-items: center;
    ${InputTextStyles};
    color: var(--main);
    font-weight: ${weights.black};
    padding: 5px 10px;
    ${props => props.noIcon && `padding: 8px 15px;`}

    ${mediaUp('lg')} {
      padding: 10px 20px;
      ${props => props.noIcon && `padding: 15px 30px;`}
    }

    &--is-focused,
    &--is-selected {
      background-color: var(--tint);
    }

    &::before {
      content: '';
      width: 21px;
      height: 21px;
      background-color: var(--tint);
      background-size: 50%;
      background-position: center;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iIzA5Mzc2OSI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTkuOTI5IDcuMDIyYzAtLjUyOS0uNDMtLjk1OC0uOTU4LS45NThoLS4wMjNjLS41MjkgMC0uOTQ3LjQyOS0uOTQ3Ljk1OCAwIC41MjkuNDQxLjk1OC45Ny45NTguNTI5IDAgLjk1OC0uNDMuOTU4LS45NTh6bS0uOTctNS4xMDZjLTIuOTUzIDAtNS4zNTYgMi40MDItNS4zNTYgNS4zNTYgMCAyLjk2OSAzLjQ0OCA2LjU5MyA1LjM1NSA4LjI1NyAxLjkwOS0xLjY2NyA1LjM1Ny01LjI5NCA1LjM1Ny04LjI1NyAwLTIuOTU0LTIuNDAyLTUuMzU2LTUuMzU2LTUuMzU2ek04Ljk2IDE4bC0uNTk1LS40NzFjLS4yNzMtLjIxNi02LjY3Ny01LjM0OC02LjY3Ny0xMC4yNTdDMS42ODggMy4yNjIgNC45NSAwIDguOTYgMHM3LjI3MiAzLjI2MiA3LjI3MiA3LjI3MmMwIDQuOTEtNi40MDQgMTAuMDQxLTYuNjc3IDEwLjI1N0w4Ljk2IDE4eiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEyODYuMDAwMDAwLCAtMTYwNC4wMDAwMDApIHRyYW5zbGF0ZSgxNTEuMDAwMDAwLCAxNDM3LjAwMDAwMCkgdHJhbnNsYXRlKDEuMDAwMDAwLCA2OC4wMDAwMDApIHRyYW5zbGF0ZSgxMTAyLjAwMDAwMCwgMC4wMDAwMDApIHRyYW5zbGF0ZSgyMC4wMDAwMDAsIDg3LjAwMDAwMCkgdHJhbnNsYXRlKDEyLjAwMDAwMCwgMTIuMDAwMDAwKSIvPgogICAgICAgICAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K');
      margin-inline-end: 10px;
      border-radius: 5px;

      ${props => props.noIcon && `content: none;`}

      ${mediaUp('lg')} {
        width: 42px;
        height: 42px;
        margin-inline-end: 20px;
        background-size: auto;
        border-radius: 10px;
      }
    }
  }
  .custom-select__menu-notice {
    ${InputTextStyles};
  }
`
