import styled from '@emotion/styled'

import { mediaColumns } from '@/styles/grid'
import { mediaDown, mediaUp } from '@/styles/media'

export const FormWrapper = styled.form`
  ${mediaColumns({ xs: [12, 0], md: [6, 6] })};

  ${mediaDown('md')} {
    padding: 0 25px;
  }

  .error--form {
    ${mediaColumns({ xs: [12, 0], md: [6, 6] })};
    margin-bottom: 15px;
    padding-inline-start: 0;
    text-align: center;
  }
`

export const ButtonWrapper = styled.div`
  width: 177px;
  margin: 0 auto;

  button {
    margin: 0 auto;
  }

  ${mediaUp('lg')} {
    width: 238px;
  }
`

export const TitleWrapper = styled.div`
  ${mediaColumns({ xs: [12, 0], md: [6, 6] })};
  text-align: center;
`
