import styled from '@emotion/styled'

import { border, colors, transitions } from '@/styles/theme'
import { mediaUp } from '@/styles/media'
import { LegiaMedium, Text2, Text2Styles } from '@/styles/typography'

export const Container = styled.div`
  margin-bottom: 18px;

  ${mediaUp('lg')} {
    margin-bottom: 40px;
  }

  .error--centered {
    padding-inline-start: 0;
    text-align: center;
  }
`

export const Wrapper = styled.div<{ isFilled: boolean }>`
  position: relative;
  cursor: pointer;
  border-radius: ${border.radiusMobile};
  box-shadow: ${border.shadow};
  background-color: ${colors.white};
  border: 1px solid transparent;
  color: var(--main);
  transition: border-color ${transitions.base};
  width: 100%;
  min-height: 68px;
  padding: 8px 25px;

  ${props => props.isFilled && `pointer-events: none;`}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  input:focus + & {
    border-color: var(--accentFirst);
  }

  ${mediaUp('lg')} {
    border-width: 2px;
    min-height: 108px;
    padding: 28px 50px 23px;
    border-radius: ${border.radius};
  }

  .legia-warszawa & {
    border-radius: 0;
  }
`

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 auto 8px;

  ${mediaUp('lg')} {
    width: 28px;
    height: 28px;
    margin-bottom: 12px;
  }

  .legia-warszawa & {
    path {
      fill: var(--accentFirstCustom);
    }
  }
`

export const Info = styled(Text2)`
  text-align: center;
  span {
    color: var(--accentFirst);
  }

  .legia-warszawa & {
    ${LegiaMedium};

    span {
      color: var(--accentFirstCustom);
    }
  }
`

export const Files = styled.ul`
  width: 100%;
`

export const UploadedFile = styled.li`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
`

export const FileName = styled(Text2)`
  color: var(--main);
  font-weight: 700;
  margin-inline-end: 5px;

  .legia-warszawa & {
    ${LegiaMedium};
    color: var(--mainCustom);
  }
`

export const FileWeight = styled(Text2)``

export const RemoveFile = styled.button`
  ${Text2Styles};
  display: block;
  margin: 0 auto;
  color: ${colors.darkCoral};
  pointer-events: all;
`
