import { useTranslation } from 'next-i18next'

import { Grid } from '@/styles/grid'
import Title from '@/components/common/Title'
import CustomReveal from '@/components/common/CustomReveal'

import { Container, TitleWrapper } from './index.styled'
import ContactInfo from './ContactInfo'
import Form from './Form'

interface Props {
  parkingName?: string
}

function ContactSection(props: Props) {
  const { parkingName } = props

  const { t } = useTranslation('common')

  return (
    <CustomReveal>
      <Container id="contact-section">
        <TitleWrapper>
          <Title text={t('contact.title')} />
        </TitleWrapper>
        <Grid>
          <ContactInfo />
          <Form parkingName={parkingName} />
        </Grid>
      </Container>
    </CustomReveal>
  )
}

export default ContactSection
