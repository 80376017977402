import React, { InputHTMLAttributes } from 'react'

import Error from '@/components/form/Error'

import { TextareaComponent } from './Textarea.styled'
import { Field, FieldLabelWrapper, FieldLabel } from './Field'

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
  error?: string
  noMargin?: boolean
  hideLabel?: boolean
  dataTestId?: string
}

function Textarea(props: Props) {
  const {
    label,
    error = '',
    noMargin = false,
    hideLabel = false,
    dataTestId,
    ...textareaAttrs
  } = props

  return (
    <Field noMargin={noMargin}>
      <FieldLabelWrapper>
        <FieldLabel visuallyHidden={hideLabel}>{label}</FieldLabel>
        <TextareaComponent
          rows={5}
          $invalid={!!error}
          {...textareaAttrs}
          data-test-id={dataTestId}
        />
      </FieldLabelWrapper>
      <Error error={error} />
    </Field>
  )
}

export default Textarea
