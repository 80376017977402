import React from 'react'
import dynamic from 'next/dynamic'
import { css } from '@emotion/react'

import { useMarket } from '@/utils/multi-markets/context'
import { weights, fonts } from '@/styles/theme'

const DigitalParkingDetails = dynamic(
  () => import('@/assets/digital-parking-details.svg?inline')
)
const CyfrowyParkingDetails = dynamic(
  () => import('@/assets/cyfrowy-parking-details.svg?inline')
)

const components = {
  en: DigitalParkingDetails,
  pl: CyfrowyParkingDetails,
  de: DigitalParkingDetails,
  ar: DigitalParkingDetails
}

function LogoDetails() {
  const { locale } = useMarket()
  const LogoComponent = components[locale]

  return <LogoComponent css={svg} />
}

const svg = css`
  direction: ltr;
  height: 100%;
  width: 100%;

  text {
    font-family: ${fonts.primary};
    font-weight: ${weights.semibold};
    font-size: 13px;
  }
`

export default LogoDetails
